<template>
	<div>
		<div class="banner">
			<div class="conteudo-interno">
				<div class="nova-comunicacao-container">
					<h1>Cadastro de Notificações</h1>
					<div class="container-btns" v-if="perfil != 5 && perfil != null">
						<router-link class="btn btn-laranja" :to="{ name: 'AdicionarNotificacao' }">
							Nova Notificação
						</router-link>
					</div>
					<form @submit.prevent class="formulario-container">
						<div class="formulario-linha flex">
							<div class="formulario-item medio">
								<label class="formulario-item-label">Tipo de notificação</label>
								<multiselect v-model="form.idTipoNotificacao" label="descricao" track-by="descricao"
									:options="selecttipodeNotificacao" :searchable="false" :multiple="false"
									placeholder="Tipo de notificação" select-label="Selecionar" deselect-label="Remover"
									selected-label="Selecionado" />
							</div>

							<div class="formulario-item medio">
								<label class="formulario-item-label">Autorizada</label>
								<multiselect v-model="form.idSegmentacao" label="descricao" track-by="descricao"
									:options="selectAutorizada" :searchable="false" :multiple="false" placeholder=" "
									select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado"
									@input="
										(assistenciaSelecionados = []), (form.idsAutorizada = [])
									" />
							</div>

							<!-- <div
								v-if="form.idSegmentacao.id == 1"
								class="formulario-item medio"
							>
								<label class="formulario-item-label">Assistência</label>
								<multiselect
									ref="assistencia"
									label="descricao"
									track-by="descricao"
									v-model="form.idsAutorizada"
									:options="assistencia"
									:searchable="true"
									:allow-empty="false"
									placeholder=""
									select-label="Selecionar"
									deselect-label="Remover"
									selected-label="Selecionado"
									@input="assistenciaSelecionado()"
								/>
							</div>

							<div
								v-if="form.idSegmentacao.id == 2"
								class="formulario-item medio"
							>
								<label class="formulario-item-label">Franquia</label>
								<multiselect
									ref="assistencia"
									label="descricao"
									track-by="descricao"
									v-model="form.idsAutorizada"
									:options="franquia"
									:searchable="true"
									:multiple="false"
									:allow-empty="false"
									placeholder=""
									select-label="Selecionar"
									deselect-label="Remover"
									selected-label="Selecionado"
									@input="assistenciaSelecionado()"
								/>
							</div>

							<div class="box-excluir-todos">
								<p
									class="btn-excluir"
									@click="(assistenciaSelecionados = []), (form.idSegmentacao = [])"
									v-if="assistenciaSelecionados.length > 0"
								>
									Limpar assistências
								</p>
							</div> -->
							<!-- <div
								id="box-filtro-assistencia"
								v-if="assistenciaSelecionados.length > 0"
							>
								<div v-if="assistenciaSelecionados.length > 0" class="tabela-informacoes">
									<div
										class="box-colunas-assistencia tabela-linha tabela-linha-informacoes quebra-linha"
									>
										<div
											v-for="(assistencia, index) in assistenciaSelecionados"
											:key="index"
											class="coluna-nome-assistencia"
										>
											<p>{{ assistencia.descricao }}</p>
											<button
												@click="
													assistenciaSelecionados.splice(
														assistenciaSelecionados.indexOf(assistencia),
														1
													)
												"
												class="btn-excluir-assistencia"
											>
												X
											</button>
										</div>
									</div>
								</div>
							</div> -->

							<div class="formulario-item medio">
								<label class="formulario-item-label">Cargo</label>
								<multiselect v-model="form.idCargo" label="descricao" track-by="descricao"
									:options="cargosFiltro" :searchable="false" :multiple="false"
									placeholder="Nome do Cargo" select-label="Selecionar" deselect-label="Remover"
									selected-label="Selecionado" />
							</div>

							<div class="formulario-item medio">
								<label class="formulario-item-label">CPF</label>
								<input type="text" v-model="form.cpf" v-mask="'###.###.###-##'" placeholder="CPF" />
							</div>

							<div class="formulario-item medio">
								<label class="formulario-item-label">Status</label>
								<multiselect v-model="form.status" label="descricao" track-by="descricao"
									:options="selectStatus" :searchable="false" :multiple="false"
									placeholder="Status da notificação" select-label="Selecionar"
									deselect-label="Remover" selected-label="Selecionado" />
							</div>

							<div class="formulario-item medio">
								<label class="formulario-item-label">Data de envio - De:</label>
								<datepicker :language="ptBR" v-model="form.dataInicio" />
							</div>

							<div class="formulario-item medio">
								<label class="formulario-item-label">Data de envio - Até:</label>
								<datepicker :language="ptBR" v-model="form.dataFim" />
							</div>
						</div>
						<div class="container-btns">
							<button class="btn btn-cancelar" @click="limparFiltro">
								Limpar
							</button>
							<button class="btn btn-laranja" @click="listar()">Filtrar</button>
						</div>
					</form>

					<transition name="fade-left" mode="out-in">
						<div class="paginacao" v-if="!semDados">
							<button :disabled="paginaBusca == 1" @click="pagAnterior">
								Anterior
							</button>
							<p>{{ paginaBusca }}</p>
							<button :disabled="ultimoItem" @click="proxPagina">
								Próxima
							</button>
						</div>
					</transition>

					<div v-if="semDados" class="tabela-sem-dados" key="Vazio">
						<p>Sem dados</p>
					</div>
					<div v-else class="tabela-informacoes" key="Lista">
						<div class="tabela-linha tabela-linha-titulo">
							<div class="tabela-linha-nome">
								<p>ID da notificação</p>
							</div>
							<div class="tabela-linha-nome">
								<p>Tipo de Notificação</p>
							</div>
							<div class="tabela-linha-nome">
								<p>Mensagem</p>
							</div>
							<div class="tabela-linha-data-inicio">
								<p>Criado em</p>
							</div>
							<div class="tabela-linha-data-final">
								<p>Status</p>
							</div>
							<div class="tabela-linha-btns"></div>
							<!-- <div class="tabela-linha-btns"></div> -->
						</div>
						<div class="tabela-linha tabela-linha-informacoes" v-for="(item, index) in dadosTabela"
							:key="index">
							<div class="tabela-linha-nome">
								<p>{{ item.id }}</p>
							</div>
							<div class="tabela-linha-nome">
								<p>{{ item.tipoNotificacao }}</p>
							</div>
							<div class="tabela-linha-nome tabela-linha-msg">
								<p>{{ item.mensagem }}</p>
							</div>
							<div class="tabela-linha-data-inicio">
								<p>{{ formatarData(item.criadoEm) }}</p>
							</div>
							<div class="tabela-linha-data-final">
								<p>{{ item.status ? "Ativo" : "Inativo" }}</p>
							</div>
							<div class="tabela-linha-btns">
								<div class="tabela-linha-btns-editar" @click="editarNotificacao(item.id)">
									<img src="@/assets/images/icones/ver-mais.png" alt="Editar" class="icone-branco" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	listarNotificacoes,
	listarTipoNotificacoes
} from "@/services/notificacoes";
// import { listarAssistencias } from "@/services/autorizadas";
import { listarCargos } from "@/services/espelho";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import Multiselect from "vue-multiselect";
import moment from "moment";
export default {
	name: "CadastroNotificacoes",

	components: {
		Multiselect,
		Datepicker
	},

	data() {
		return {
			ptBR: ptBR,
			selectAutorizada: [
				{ id: 1, descricao: "Assistência" },
				{ id: 2, descricao: "Franquia" }
			],
			selecttipodeNotificacao: [],
			cargos: [],
			cargosFiltro: [],
			selectStatus: [
				{ id: true, descricao: "Ativo" },
				{ id: false, descricao: "Inativo" }
			],
			franquiaAutorizada: [],
			assistenciaSelecionados: [],
			assistencia: [],
			franquia: [],
			dadosTabela: [],
			nome: [],
			autorizada: [],
			semDados: true,
			paginaBusca: 1,
			ultimoItem: false,
			form: {
				idTipoNotificacao: 0,
				cpf: "",
				idCargo: 0,
				idAutorizada: 0,
				idsAutorizada: [],
				idSegmentacao: "",
				dataInicio: "",
				dataFim: "",
				status: true
			},
			perfil: null
		};
	},

	watch: {
		"form.idSegmentacao"() {
			let idAssistencias = [1, 4, 7, 8, 9, 10];
			let idFranquias = [5, 6];
			if (this.form.idSegmentacao.id == 1) return this.cargosFiltro = this.cargos.filter(item => idAssistencias.find(i => i == item.id));
			if (this.form.idSegmentacao.id == 2) return this.cargosFiltro = this.cargos.filter(item => idFranquias.find(i => i == item.id));
		}
	},

	created() {
		this.listar(1);
		this.listaCargos();
		this.listaTipodeNotificacoes();
		this.perfil = localStorage.getItem('perfil');
		// this.buscarAssistenciasSegmentacao();
	},
	methods: {
		// buscarAssistenciasSegmentacao() {
		// 	listarAssistencias(1).then(resp => {
		// 		this.assistencia = resp.data;
		// 	});
		// 	listarAssistencias(2).then(resp => {
		// 		this.franquia = resp.data;
		// 	});
		// },
		listaTipodeNotificacoes() {
			listarTipoNotificacoes().then(resp => {
				this.selecttipodeNotificacao = resp.data;
			});
		},
		listaCargos() {
			listarCargos().then(resp => {
				this.cargos = resp.data;
				this.cargosFiltro = resp.data;
			});
		},
		limparFiltro() {
			this.form = {
				idTipoNotificacao: 0,
				cpf: "",
				idCargo: 0,
				idAutorizada: 0,
				idsAutorizada: [],
				idSegmentacao: "",
				dataInicio: "",
				dataFim: "",
				status: true
			};
			this.assistenciaSelecionados = [];

			this.listar();
		},
		formatarData(data) {
			return moment(data).format("DD/MM/YYYY");
		},
		assistenciaSelecionado() {
			if (this.form.idsAutorizada != null) {
				this.assistenciaSelecionados.push(this.form.idsAutorizada);
				this.form.idsAutorizada = this.assistenciaSelecionados;
			}
		},
		listar(pag) {
			const data = {
				idTipoNotificacao: this.form.idTipoNotificacao.id
					? this.form.idTipoNotificacao.id
					: 0,
				cpf: this.form.cpf
					? this.form.cpf.replaceAll(".", "").replace("-", "")
					: "",
				idCargo: this.form.idCargo.id ? this.form.idCargo.id : 0,
				idAutorizada: this.form.idSegmentacao.id
					? this.form.idSegmentacao.id
					: 0,
				dataInicio: this.form.dataInicio
					? moment(this.form.dataInicio).format("YYYY-MM-DD 23:59:00")
					: "",
				dataFim: this.form.dataFim
					? moment(this.form.dataFim).format("YYYY-MM-DD 23:59:00")
					: "",
				status: this.form.status ? this.form.status.id : false,
				pagina: pag,
				qtdPorPagina: 10
			};

			listarNotificacoes(data).then(resp => {
				if (resp.data.lista.length == 0) {
					this.dadosTabela = [];
					if (pag && pag > 1) {
						this.ultimoItem = true;
					} else {
						this.semDados = true;
					}
				} else {
					resp.data.lista.length < 10
						? (this.ultimoItem = true)
						: (this.ultimoItem = false);
					if (pag) {
						this.paginaBusca = pag;
					}
					this.semDados = false;
					this.dadosTabela = resp.data.lista;
				}
			});
		},
		pagAnterior() {
			const pag = --this.paginaBusca;
			this.listar(pag);
		},
		proxPagina() {
			const pag = ++this.paginaBusca;
			this.listar(pag);
		},
		editarNotificacao(id) {
			this.$router.push(`editar/${id}`);
		}
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style lang="scss">
.banner {
	position: relative;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 100%;

	.tabela-linha-btns {
		min-width: 50px;
		max-width: 50px;
	}

	.flex {
		display: flex;
		justify-content: flex-start;
	}

	.medio {
		width: 33.33% !important;
	}

	.tabela-linha-nome {
		width: auto;
		white-space: normal;
	}

	.icone-branco {
		filter: invert(1);
	}

	.box-excluir-todos {
		width: 100%;
		margin-left: 15px;
		display: flex;
	}

	.btn-excluir-assistencia,
	.btn-excluir {
		margin: 10px 0;
		font-weight: 800;
		width: auto;
		color: #fff;
		padding: 10px;
		border-radius: 5px;
		background: #f28809;
		text-align: center;
		cursor: pointer;
		display: block;
	}

	.btn-excluir-assistencia {
		padding: 5px;
		border: none;
		width: 30px;
	}

	#box-filtro-assistencia {
		width: 100%;

		.tabela-informacoes {
			height: auto;
			max-height: 100px;
		}

		.tabela-linha.tabela-linha-informacoes .coluna-nome-assistencia {
			width: 250px;
			padding: 0px 10px;
			margin-bottom: 4px;
		}

		.quebra-linha {
			flex-wrap: wrap;
		}

		.box-colunas-assistencia {
			width: 100%;
			display: flex;
		}
	}
}
</style>
